import React from 'react';
import {
  FaHandHoldingUsd,
  FaSuitcase,
  FaBaby,
  FaUserFriends,
  FaAddressCard,
  FaIdCardAlt,
  FaFileSignature,
  FaGavel,
  FaFileInvoice
} from 'react-icons/fa';

import SEO from '~/components/SEO';
import Title from '~/components/Title';
import Layout from '~/components/Layout';

import { Container, AreaAtuacao } from '~/styles/pages/areas-de-atuacao';

const AreasExpertisePage = () => {
  const areas = [
    {
      name: 'Direito Tributário',
      icon: <FaHandHoldingUsd />,
      listItens: ['Organização e reorganização tributária.', 'Atualização e informação às empresas a respeito das novas legislações tributárias.', 'Defesas fiscais, planejamento tributário preventivo e recuperação de tributos pagos indevidamente.', 'Redução legal e estratégica de custos fiscais.', 'Medidas administrativas ou judiciais nas esferas municipal, estadual e federal, através da análise e de auditoria fiscal.']
    },
    {
      name: 'Direito Empresarial',
      icon: <FaSuitcase />,
      listItens: ['Fusão.', 'Cisão.', 'Incorporação.', 'Dissolução e Liquidação.', 'Organização e reorganização societária.', 'Constituição e transformação de sociedades empresariais.'],
      content: 'Com larga atuação neste ramo, a Casagrande Advogados Associados oferece a mais ampla Assessoria Jurídica Especializada para sua empresa. Para isto, oferecemos:'
    },
    {
      name: 'Direito de Família',
      icon: <FaBaby />,
      content: 'Divórcios, separações e defesas de interesse de menores contam com profissionais experientes.'
    },
    {
      name: 'Direito Civil',
      icon: <FaUserFriends />,
      content: 'Nesta esfera do direito a Casagrande Advogados Associados, busca a adequação do conjunto de normas jurídicas que dispõem sobre as relações particulares, atuando na elaboração de contratos, ações de cobrança (também com busca e apreensão) ações de reparação por dano moral, patrimonial e abalo de crédito, ações de responsabilidade civil, medidas cautelares, entre outras ações de cunho civil.'
    },
    {
      name: 'Previdência Social',
      icon: <FaAddressCard />,
      listItens: ['Aposentadoria.', 'Atualizações de benefícios.', 'Assegurar beneficios não deferidos administrativamente.'],
      content: 'Atua-se na defesa de interesse de segurados da Previdencia Social, com a busca do pleno acesso aos benefícios.'
    },
    {
      name: 'Direito Trabalhista',
      icon: <FaIdCardAlt />,
      listItens: ['Estágio', 'Representação Comercial.', 'Atuação Judicial em causas trabalhistas.', 'Implantação e reorganização de contratos de terceirização.'],
      content: 'A assessoria neste ramo do direito visa orientar, prevenir e conciliar conflitos de interesses. Reorganização na relação entre empregador e empregado, minimizando assim o risco das autuações trabalhistas e litígios.'
    },
    {
      name: 'Análise de Contratos',
      icon: <FaFileSignature />,
      content: 'Orientações e defesas quanto a leis específicas e procedimentos especiais de cunho legal.'
    },
    {
      name: 'Sustentações Orais',
      icon: <FaGavel />,
      content: 'Atuação junto a Tribunais, inclusive com sustentações orais nas causas relacionadas ás áreas em que atuamos.'
    },
    {
      name: 'Sucessões',
      icon: <FaFileInvoice />,
      content: 'Atuamos em inventários judiciais ou extrajudiciais, conforme requerer o caso concreto.'
    }
  ];

  return (
    <Layout>
      <SEO title="Àreas de Atuação" description="Contamos com uma vasta àrea de atuação no direito, inclusive com ampla atuação perante os Tribunais Superiores." />
      <Title content="Áreas de Atuação" icon={<FaGavel />} />
      <Container>
        {areas.map((area) => (
          <AreaAtuacao key={area.name} id={area.name.replace(/\W/g, '')}>
            <h3>{area.icon} {area.name}</h3>
            {area.content && <p>{area.content}</p>}
            {area.listItens && <ul>{area.listItens.map((text) => <li>{text}</li>)}</ul>}
          </AreaAtuacao>
        ))}
      </Container>
    </Layout>
  );
};

export default AreasExpertisePage;
