import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  align-self: center;

  width: 100%;
  max-width: 1140px;
`;

export const AreaAtuacao = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 30px;

  & + div {
    padding-top: 30px;
    border-top: 1px solid rgba(0,0,0,0.1);
  }

  h3 {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.green};
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 10px;

    svg {
      width: 40px;
      height: 40px;
      color: ${({ theme }) => theme.green};
      margin-right: 10px;
    }
  }
  p, ul li{
    text-indent: 20px;
    color: #666;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;
    /* margin: 0; */
  }

  ul {
    margin-top: 5px;
    padding-left: 20px;
    list-style: initial;
  }

  ${media.lessThan('large')`
    margin: 0 10px 20px;
    padding: 0 20px;
    
    h3 {
      justify-content: center;
      margin-bottom: 7px;
      svg {
        width: 30px;
        height: 30px;
        margin-right: 5px;
      }
    }
    p, ul li {
      text-indent: 10px;
      font-size: 12px;
      line-height: 18px;
    }
    ul {
      padding-left: 10px;
    }
    & + div {
      padding-top: 20px;
      border-top: 1px solid rgba(0,0,0,0.2);
    }
  `};
`;
